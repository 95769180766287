.rpv-core__inner-page {
  background-color: transparent !important;
}

.rpv-default-layout__toolbar {
  height: 3rem !important;
  border: 0px !important;
}

.rpv-default-layout__body {
  padding-top: 3rem !important;
}

.rpv-default-layout__container {
  border: 0px !important;
}

.rpv-core__inner-pages {
  @media screen and (max-width:600px) {
    margin-bottom: 5rem;
  }
}

@media screen and (min-width:600px) {
  .rpv-default-layout__body {
    &::before {
      content: '';
      top: 3rem;
      right: 0;
      left: 2.75rem;
      bottom: 0;
      position: absolute;
      pointer-events: none;
      z-index: 999;
      box-shadow: inset 3px 3px 3px 0px rgb(0 0 0 / 30%);
    }
  }

  [dir='rtl'] {
    .rpv-default-layout__body {
      &::before {
        right: 2.75rem;
        left: 0;
        box-shadow: inset -3px 3px 3px 0px rgb(0 0 0 / 30%);
      }
    }
  }
}

.rpv-core__page-layer {
  background-color: white !important;
}

.rpv-default-layout__sidebar {
  margin-top: 3rem !important;
  padding: 0 !important;
  border: 0px !important;

  &--opened {
    left: auto !important;
  }
}

.rpv-default-layout__sidebar-headers {
  background-color: white !important;

  @media screen and (max-width:600px) {
    display: none;
  }
}

.rpv-core__splitter {
  display: none !important;
}